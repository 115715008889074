import React from "react"

//import the bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

//import the Header component
import Header from '../components/Header';
//import the AlbumList component
import AlbumList from '../components/AlbumList';

//import Bootstrap stuff we're using
import Container from 'react-bootstrap/Container';

//read albumdata from json
import AlbumData from "../../content/index.json"

export default function Home() {
  return (
    <Container >
    <Header title={AlbumData.title} />
    <AlbumList albums={AlbumData.albums} className="justify-content-center" />
    </Container>
  )
}
